import { getGeoPointFromWKTPoint } from '~/utility/geo/GeoLocation'
import { getUrlLocaleCode } from '~~/lang/locales'
import { langFallback$Fetch } from '../../../../../lang/utils/langfetch'
import { LocationSuggestionHierarchy } from '../domain/LocationSuggestions'
import { HierarchyResponse, hierarchyResponseTypeSchema } from '../schemas'

export default class FetchLocationHierarchySuggestions {
  async fetch(
    query: string,
    langCode: string,
  ): Promise<LocationSuggestionHierarchy[]> {
    const response = await langFallback$Fetch<HierarchyResponse[]>(
      langCode,
      `/search/predictive/hierarchy/?q=${query}`,
    )
    const data = hierarchyResponseTypeSchema.array().parse(response)
    return this.getParsedHierarchies(
      data,
      langCode,
    ) as LocationSuggestionHierarchy[]
  }

  private getParsedHierarchies(
    hierarchies: HierarchyResponse[],
    langCode: string,
  ) {
    return hierarchies.map((hierarchy) => {
      return {
        ...hierarchy,
        path: this.getHierarchyPathFromUrl(hierarchy.url, langCode),
        bookableCount: hierarchy.bookable_count_number,
        bookableCountDisplay: hierarchy.bookable_count,
        bookableString: hierarchy.bookable_string,
        point: this.mockPoint(hierarchy),
      }
    })
  }

  private getHierarchyPathFromUrl(url: string, locale: string) {
    const prefix = getUrlLocaleCode(locale)
    const urlPrefix = `/${prefix}/`
    let outputUrl = url.slice()
    if (url.startsWith(urlPrefix)) {
      outputUrl = outputUrl.replace(urlPrefix, '/')
    }
    return outputUrl.split('/').slice(2).join('/')
  }

  private mockPoint(hierarchy: HierarchyResponse) {
    return hierarchy.type === 3
      ? getGeoPointFromWKTPoint('POINT(0 0)')
      : undefined
  }
}
