
import { usePinia, useSearchWizardStore } from '#imports'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import { GeoPoint } from '~/utility/geo/GeoLocation'
import {
  LocationWizardCampsiteData,
  LocationWizardCustomData,
  LocationWizardData,
  LocationWizardHierarchyData,
} from '../../../domain/LocationWizardData'
import { LocationSuggestions } from '../domain/LocationSuggestions'
import { AutoSuggestData } from '../types'

interface SearchTerm {
  term?: string
}
type LocationWizardDataEvent =
  | (LocationWizardCampsiteData & SearchTerm)
  | (LocationWizardCustomData & SearchTerm)
  | (LocationWizardHierarchyData & SearchTerm)

@Component({
  setup() {
    const searchWizardStore = useSearchWizardStore(usePinia())
    return { searchWizardStore }
  },
})
export default class LocationSection extends Vue {
  searchWizardStore: ReturnType<typeof useSearchWizardStore>

  get area() {
    return this.searchWizardStore.context.filters.area
  }

  @Prop({ default: '' })
    value: string

  @Prop({ required: true })
    focused: boolean

  @Prop()
    selectedLocationType: string | null

  @Prop()
    selectedLocation: LocationWizardData | null

  @Prop()
    inputPlaceholder?: string

  @Prop()
    hasBounds: boolean

  @Prop()
    searchInput: string

  @Prop()
    suggestions: LocationSuggestions

  @Prop()
    forceSuggestionsOpen: boolean

  @Prop({ default: false })
    showInputOnly?: boolean

  locationChanged(data: LocationWizardDataEvent) {
    const { ...location } = data
    switch (location.dataType) {
      case 'campsite':
        this.$emit('locationChanged', { location })
        break
      case 'hierarchy':
        this.$emit('locationChanged', { location })
        break
      case 'custom':
        this.$emit('locationChanged', { location })
        break
    }
    // this.$emit('locationChanged', { location, term })
    // return { location, term }
  }

  updateSuggestions(input: string) {
    this.$emit('updateSuggestions', input)
  }

  suggestionSelected(data: LocationWizardDataEvent) {
    const { term, name } = data
    // this is for the suggestionmachine
    this.$emit('selectLocation', term || name)
    this.locationChanged(data)
  }

  get within(): number {
    return (
      this.searchWizardStore.within || this.$config.public.defaultWithinRange
    )
  }

  get mergedSuggestions(): AutoSuggestData {
    const suggestionsFormatted: AutoSuggestData = []
    if (this.suggestions.hierarchies) {
      suggestionsFormatted.push({
        name: 'hierarchy',
        data: this.suggestions.hierarchies,
      })
    }
    if (this.suggestions.campsites) {
      suggestionsFormatted.push({
        name: 'campsite',
        data: this.suggestions.campsites,
      })
    }
    if (this.suggestions.custom) {
      suggestionsFormatted.push({
        name: 'custom',
        data: this.suggestions.custom,
      })
    }
    return suggestionsFormatted
  }

  get searchType() {
    return this.searchWizardStore?.context?.searchInputValue?.type || ''
  }

  async pointChanged(point: GeoPoint | null) {
    // if we have a point, send it to the wizard with default within
    // otherwise clear the existing point
    const area = point
      ? {
        ...point,
        within: this.$config.public.defaultWithinRange,
      }
      : undefined
    if (area) {
      this.searchWizardStore.send('SET_MY_LOCATION', { area })
    } else {
      this.searchWizardStore.send('CLEAR_INPUT')
    }

    await this.searchWizardStore.updateCounts()
  }

  @Watch('open')
  onOpenChange(opened: boolean) {
    if (opened && this.searchInput.length > 0) {
      this.updateSuggestions('')
    }
  }
}
