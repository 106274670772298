import { render, staticRenderFns } from "./LocationSectionSuggestableInput.vue?vue&type=template&id=840790e2&scoped=true"
import script from "./LocationSectionSuggestableInput.vue?vue&type=script&lang=ts"
export * from "./LocationSectionSuggestableInput.vue?vue&type=script&lang=ts"
import style0 from "./LocationSectionSuggestableInput.vue?vue&type=style&index=0&id=840790e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "840790e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationWizardSuggestionLine: require('/app/apps/search-wizard/campsites-search/location/components/suggestions/LocationWizardSuggestionLine.vue').default,Translate: require('/app/components/Translate.vue').default})
