import { render, staticRenderFns } from "./LocationSection.vue?vue&type=template&id=648c0540&scoped=true"
import script from "./LocationSection.vue?vue&type=script&lang=ts"
export * from "./LocationSection.vue?vue&type=script&lang=ts"
import style0 from "./LocationSection.vue?vue&type=style&index=0&id=648c0540&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "648c0540",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationSectionSuggestableInput: require('/app/apps/search-wizard/campsites-search/location/components/LocationSectionSuggestableInput.vue').default,GeolocationCheckbox: require('/app/components/geolocation/components/GeolocationCheckbox.vue').default})
