
import { VueAutosuggest } from 'vue-autosuggest'
import { Component, Prop, Vue, Watch } from '~/utility/pu-class-decorator'
import {
  LocationWizardCampsiteData,
  LocationWizardCustomData,
  LocationWizardHierarchyData,
} from '../../../domain/LocationWizardData'
import {
  LocationSuggestionCampsite,
  LocationSuggestionCustom,
  LocationSuggestionHierarchy,
} from '../domain/LocationSuggestions'
import { AutoSuggestData, LocationSuggestion } from '../types'

@Component({
  components: {
    VueAutosuggest,
  },
})
export default class LocationSectionSuggestableInput extends Vue {
  @Prop({ default: '' })
    value: string

  @Prop({ required: true })
    suggestions: AutoSuggestData

  @Prop()
    searchType: string

  @Prop({ required: true })
    focused: boolean

  @Prop()
    placeholder?: string

  @Prop()
    forceSuggestionsOpen: boolean

  @Prop({ default: true })
    showHelpText: boolean

  alreadySelected = false
  inputSize = 18
  private menuBlur = true
  private hasScrolledInput = false
  sectionConfigs = {
    default: {},
    campsite: {
      limit: 3,
      onSelected: ({ item }: { item: LocationSuggestionCampsite }) => {
        this.onCampsiteSelected(item)
      },
    },
    hierarchy: {
      limit: 3,
      onSelected: ({ item }: { item: LocationSuggestionHierarchy }) => {
        this.onHierarchySelected(item)
      },
    },
    custom: {
      limit: 3,
      onSelected: ({ item }: { item: LocationSuggestionCustom }) => {
        this.onCustomSelected(item)
      },
    },
  }

  mounted() {
    this.onFocusedChange(this.focused)
  }

  shouldRenderSuggestionsHandler(size: number, loading: boolean) {
    if (this.forceSuggestionsOpen && size > 0 && loading) {
      // need to force click so the autosuggest component does its thing and listens on click
      this.searchInputElement.click()
      return true
    }
    // default autosuggest behaviour
    return size > 0 && !loading
  }

  get autosuggestSuggestions() {
    if (!this.suggestions.length) return this.suggestions
    return this.suggestions.length === 1 &&
      this.suggestions[0].name === 'custom'
      ? this.suggestions
      : this.suggestions.filter((s) => s.name !== 'custom')
  }

  get shouldShowClearInputButton() {
    if (this.searchType === 'CUSTOM' && this.value) return true
    if (this.searchType === 'BOUNDS' || this.searchType === 'LOCATION')
      return true
    return false
  }

  get inputProps() {
    return {
      id: 'autosuggest__input',
      placeholder: this.placeholder,
      class: 'autosuggest-input',
      name: 'q',
      size: this.inputSize,
    }
  }

  get searchInputElement() {
    return document.querySelector('.autosuggest-input') as HTMLElement
  }

  private onCampsiteSelected(item: LocationSuggestionCampsite, term?: string) {
    this.alreadySelected = !term
    const { name, slug, inputValue, hierarchyPath } = item
    this.campsiteSelected(
      {
        dataType: 'campsite',
        name,
        slug,
        hierarchyPath,
        inputValue,
      },
      term,
    )
  }

  private onHierarchySelected(
    item: LocationSuggestionHierarchy,
    term?: string,
  ) {
    this.alreadySelected = !term
    const { name, path, point, type, bookableCount } = item
    this.hierarchySelected(
      {
        dataType: 'hierarchy',
        name,
        path,
        point,
        type,
        bookableCount,
      },
      term,
    )
  }

  private onCustomSelected(item: LocationSuggestionCustom, term?: string) {
    this.alreadySelected = !term
    const { name, point } = item
    this.customLocationSelected({ dataType: 'custom', name, point }, term)
  }

  @Watch('focused')
  onFocusedChange(focused: boolean) {
    if (focused) {
      this.$nextTick(() => {
        this.searchInputElement.focus()
        this.scrollToSearchInput()
      })
    }
  }

  campsiteSelected(data: LocationWizardCampsiteData, term?: string) {
    const campsiteSelected = { ...data, term }
    this.$emit('campsiteSelected', campsiteSelected)
    return campsiteSelected
  }

  hierarchySelected(data: LocationWizardHierarchyData, term?: string) {
    const hierarchySelected = { ...data, term }
    this.$emit('hierarchySelected', hierarchySelected)
    return hierarchySelected
  }

  customLocationSelected(data: LocationWizardCustomData, term?: string) {
    const customLocationSelected = { ...data, term }
    this.$emit('customLocationSelected', customLocationSelected)
    return customLocationSelected
  }

  inputBlur() {
    this.hideOverlay()
    this.$emit('autosuggestionClosed')
    this.$bubble('blur')
  }

  inputFocus() {
    this.$bubble('focus')
    this.showOverlay()
  }

  inputClick() {
    this.scrollToSearchInput()
  }

  suggestionsClosed() {
    this.hideOverlay()
    if (this.alreadySelected || !this.suggestions.length) return
    let suggestionType: string
    let item: LocationSuggestion | null
    if (this.shouldSetCustomOnBlur) {
      suggestionType = 'custom'
      item = this.customSuggestionsDefaultItem
    } else {
      suggestionType = this.autosuggestSuggestions[0].name
      item = this.defaultSuggestion
    }
    if (!item) return
    const selectorTrigger = this.getSuggestionNameSelector(suggestionType)
    selectorTrigger(item, this.value)
  }

  private get shouldSetCustomOnBlur() {
    return (
      this.suggestions.length &&
      this.autosuggestSuggestions[0].name === 'campsite' &&
      this.customSuggestions
    )
  }

  private get customSuggestionsDefaultItem() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.customSuggestions!.data.length
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.customSuggestions!.data[0]
      : null
  }

  private get defaultSuggestion() {
    return this.suggestions[0].data.length ? this.suggestions[0].data[0] : null
  }

  private get customSuggestions() {
    const suggestions = this.suggestions.filter((s) => s.name === 'custom')
    return suggestions.length ? suggestions[0] : undefined
  }

  private getSuggestionNameSelector(type: string) {
    const suggestionNameToSelectorMap = {
      campsite: this.onCampsiteSelected,
      hierarchy: this.onHierarchySelected,
      custom: this.onCustomSelected,
    }
    return suggestionNameToSelectorMap[type]
  }

  scrollToSearchInput() {
    if (!this.hasScrolledInput) {
      this.hasScrolledInput = true
      const elementToScrollTo = this.$isDesktop
        ? '[data-desktop-location-scroll-target]'
        : '[data-search-location-scroll-target]'
      this.$puScrollTo(elementToScrollTo, {
        duration: 300,
        offset: -18,
      })
    }
  }

  clearInput() {
    this.$emit('input', '')
    this.$emit('inputChanged', '')
    this.$emit('clearInput', true)
    this.hideOverlay()
    this.searchInputElement.focus()
  }

  inputChanged(input: string) {
    this.$emit('inputChanged', input)
    this.$emit('input', input)
    this.showOverlay()
  }

  getIsMenuOpen() {
    return this.suggestions.length && this.value.length && !this.menuBlur
  }

  getInputValue({ name, item }: { name: string; item: LocationSuggestion }) {
    return name === 'campsite'
      ? (item as LocationSuggestionCampsite).inputValue
      : (item as LocationSuggestionHierarchy).name
  }

  private showOverlay() {
    this.menuBlur = false
  }

  private hideOverlay() {
    this.menuBlur = true
  }
}
