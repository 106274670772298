import * as z from 'zod'

export const campsiteResponseTypeSchema = z.object({
  value: z.string(),
  name: z.string(),
  hierarchyTextShortCountry: z.string(),
  url: z.string(),
  thumb: z.string(),
  tokens: z.string().array(),
})

export const hierarchyResponseTypeSchema = z.object({
  name: z.string(),
  level: z.string(),
  bookable_count: z.string(),
  bookable_count_number: z.number(),
  bookable_string: z.string(),
  url: z.string(),
  type: z.number(),
})

export type CampsiteResponse = z.infer<typeof campsiteResponseTypeSchema>

export type HierarchyResponse = z.infer<typeof hierarchyResponseTypeSchema>
