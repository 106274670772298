import { assign, createMachine, Interpreter } from 'xstate'
import {
  LocationSuggestionCampsite,
  LocationSuggestionCustom,
  LocationSuggestionHierarchy,
  LocationSuggestions,
} from '../domain/LocationSuggestions'
import { Events } from './events'

interface Context {
  input: string
  langCode: string
  suggestions: LocationSuggestions
}

function getContextInitial() {
  return {
    input: '',
    langCode: 'en-gb',
    suggestions: {},
  }
}

export function returnSuggestionsMachine() {
  return createMachine<Context, Events>(
    {
      id: 'suggestions',
      context: getContextInitial(),
      initial: 'idle',
      on: {
        SET_INPUT: [
          {
            target: 'debouncing',
            cond: (_, evt) => evt.input.length > 0,
            actions: 'assignInput',
          },
          {
            target: 'clear',
          },
        ],
        SET_INITIAL_INPUT: {
          actions: assign({
            input: (_, evt) => evt.input,
          }),
        },
        SELECT_LOCATION: {
          actions: assign({
            input: (_, evt) => (evt.name ? evt.name : ''),
          }),
        },
      },
      states: {
        idle: {},
        debouncing: {
          after: {
            400: 'loading',
          },
        },
        loading: {
          initial: 'loadingCampsiteAndHierarchy',
          states: {
            loadingCampsiteAndHierarchy: {
              type: 'parallel',
              states: {
                hierarchy: {
                  initial: 'loading',
                  states: {
                    loading: {
                      invoke: {
                        src: 'getHierarchySuggestions',
                        onDone: {
                          target: 'done' as const,
                          actions: 'assignHierarchySuggestions',
                        },
                        onError: {
                          target: 'done' as const,
                          actions: ['logError', 'resetHierarchySuggestions'],
                        },
                      },
                    },
                    done: {
                      always: {
                        target:
                          '#suggestions.loading.loadedCampsiteAndHierarchy',
                        cond: 'campsiteAndHierarchySuggestionsLoaded',
                      },
                    },
                  },
                },
                campsite: {
                  initial: 'loading',
                  states: {
                    loading: {
                      invoke: {
                        src: 'getCampsiteSuggestions',
                        onDone: {
                          target: 'done' as const,
                          actions: 'assignCampsiteSuggestions',
                        },
                        onError: {
                          target: 'done' as const,
                          actions: ['logError', 'resetCampsiteSuggestions'],
                        },
                      },
                    },
                    done: {
                      always: {
                        target:
                          '#suggestions.loading.loadedCampsiteAndHierarchy',
                        cond: 'campsiteAndHierarchySuggestionsLoaded',
                      },
                    },
                  },
                },
              },
            },
            loadedCampsiteAndHierarchy: {
              always: [
                {
                  target: 'loadingCustomGeo',
                  cond: 'emptyHierarchy',
                },
                { target: 'done', actions: 'resetCustomSuggestions' },
              ],
            },
            loadingCustomGeo: {
              invoke: {
                src: 'getCustomGeoSuggestions',
                onDone: {
                  target: 'done',
                  actions: 'assignCustomSuggestions',
                },
                onError: {
                  target: 'done' as const,
                  actions: ['logError', 'resetCustomSuggestions'],
                },
              },
            },
            done: {
              always: { target: '#suggestions.idle' },
            },
          },
        },
        clear: {
          entry: ['clearInput'],
          always: 'idle',
        },
      },
    },
    {
      actions: {
        assignHierarchySuggestions: assignSuggestions<{
          data: LocationSuggestionHierarchy[]
        }>(({ data }) => ({ hierarchies: data.length > 0 ? data : undefined })),
        resetHierarchySuggestions: assignSuggestions(() => ({
          hierarchies: undefined,
        })),
        assignCampsiteSuggestions: assignSuggestions<{
          data: LocationSuggestionCampsite[]
        }>(({ data }) => ({ campsites: data.length > 0 ? data : undefined })),
        resetCampsiteSuggestions: assignSuggestions(() => ({
          campsites: undefined,
        })),
        assignCustomSuggestions: assignSuggestions<{
          data: LocationSuggestionCustom[]
        }>(({ data }) => ({ custom: data.length > 0 ? data : undefined })),
        resetCustomSuggestions: assignSuggestions(() => ({
          custom: undefined,
        })),
        assignInput: assign({
          input: (_, evt) =>
            evt.type === 'SET_INPUT' || evt.type === 'SET_INITIAL_INPUT'
              ? evt.input
              : '',
        }),
        // @ts-ignore
        clearInput: assign({
          input: () => '',
          suggestions: () => ({}),
          langCode: (ctx) => ctx.langCode || 'en-gb',
        }),
        logError: () => {
          throw new Error('suggestionsMachine logError not implemented')
        },
      },
      guards: {
        emptyHierarchy: (ctx) => !ctx.suggestions.hierarchies,
        campsiteAndHierarchySuggestionsLoaded: (_ctx, _evt, { state }) =>
          state.matches('loading.loadingCampsiteAndHierarchy.hierarchy.done') &&
          state.matches('loading.loadingCampsiteAndHierarchy.campsite.done'),
      },
    },
  )
}

function assignSuggestions<TEventPayload>(
  modificationFn: (evt: TEventPayload) => Partial<Context['suggestions']>,
) {
  return assign<Context, Events>({
    suggestions: ({ suggestions }, evt) => ({
      ...suggestions,
      ...modificationFn(evt as any),
    }),
  })
}

export type SuggestionsMachineInterpret = Interpreter<Context, any, Events>
