import { RecentCampsite } from './types'

export class RecentCampsiteRepositoryLocalStorage {
  private localStorageKey = 'recentCampsites'
  private maxStorageEntries = 20

  add(id: string): void {
    const newEntry = {
      id,
      visitDate: new Date().toISOString(),
    }
    const storageRawData = localStorage.getItem(this.localStorageKey) || ''
    let data: Entry[]
    if (!storageRawData) {
      data = [newEntry]
    } else {
      data = (JSON.parse(storageRawData) as Entry[]).filter(
        (entry) => entry.id !== id,
      )
      data.unshift(newEntry)
    }
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(data.slice(0, this.maxStorageEntries)),
    )
  }

  getAll(_langCode: string, limit?: number): RecentCampsite[] {
    const data: Entry[] = JSON.parse(
      localStorage.getItem(this.localStorageKey) || '[]',
    )
    return data.slice(0, limit)
  }
}

interface Entry {
  id: string
  visitDate: string
}
