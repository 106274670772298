import { LocationWizardData } from '../../../domain/LocationWizardData'
import { mutateAreaPoint } from '~/utility/geo/mutate-area'
import { SearchWizardFilters } from '../../domain/SearchWizardFilters'

export function locationDataFilters({
  filters,
  data,
}: {
  data: LocationWizardData
  filters: SearchWizardFilters
}): SearchWizardFilters {
  const hierarchyPath = locationDataToHierarchyPath(data)
  const point = locationDataToPoint(data)
  return {
    ...filters,
    area: mutateAreaPoint(filters.area, point, {
      defaultWithinMiles: parseInt(process.env.defaultWithinRange!, 10),
    }),
    hierarchyPath,
  }
}

function locationDataToHierarchyPath(
  payload: LocationWizardData,
): string | undefined {
  const hierarchyPathFromHierarchyData =
    'path' in payload ? payload.path : undefined
  const hierarchyPathFromCampsiteData =
    'hierarchyPath' in payload ? payload.hierarchyPath : undefined
  return hierarchyPathFromHierarchyData || hierarchyPathFromCampsiteData
}

function locationDataToPoint(data: LocationWizardData) {
  if (data.dataType === 'custom') {
    return 'point' in data ? data.point : undefined
  }
  return undefined
}
