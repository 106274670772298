import { langFallback$Fetch } from '../../../../../lang/utils/langfetch'
import { LocationSuggestionCampsite } from '../domain/LocationSuggestions'
import { CampsiteResponse, campsiteResponseTypeSchema } from '../schemas'

export default class FetchLocationCampsiteSuggestions {
  async fetch(
    query: string,
    langCode: string,
  ): Promise<LocationSuggestionCampsite[]> {
    const response = await langFallback$Fetch<CampsiteResponse[]>(
      langCode,
      `/search/predictive/campsite/?q=${query}`,
    )
    const data = campsiteResponseTypeSchema.array().parse(response)

    return this.getParsedCampsites(data) as LocationSuggestionCampsite[]
  }

  private getParsedCampsites(campsites: CampsiteResponse[]) {
    return campsites.map((campsite) => ({
      ...campsite,
      inputValue: campsite.value,
      hierarchyPath: campsite.tokens[1],
      slug: this.getCampsiteSlugFromUrl(campsite.url) as string,
    }))
  }

  private getCampsiteSlugFromUrl(url: string) {
    return url.split('/').slice(0, -1).pop()
  }
}
