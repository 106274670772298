import { assign, createMachine, Interpreter } from 'xstate'
import { addDays } from '~/utility/date/relative'
import { getDefaultSearchFilters } from '../../../search/defaults'
import { SearchFilters } from '../../../search/schemas'
import { LocationWizardData } from '../../domain/LocationWizardData'
import { Events } from './events'
import { locationDataFilters } from './helpers/locationDataFilters'

interface Context {
  filters: SearchFilters
  selectedLocation?: LocationWizardData | undefined
  searchInputValue?:
  | { type: 'WAITING' }
  | { type: 'LOCATION' }
  | { type: 'BOUNDS' }
  | { type: 'CUSTOM'; text: string }
  | undefined
  searchTerm?: string
}

function getContextInitial(): Context {
  return {
    filters: getDefaultSearchFilters(),
    searchInputValue: undefined,
    selectedLocation: undefined,
  }
}

// TODO: have an update counts state and promise in machine
// when the SET_XX events are sent
// it goes to updates counts
// and then back to idle
const isDev = process.env.NODE_ENV !== 'production'

export function getCampsitesWizardMachine() {
  return createMachine<Context, Events>(
    {
      id: 'campsitesWizard',
      context: getContextInitial(),
      initial: 'idle',
      on: {
        FOCUS_LOCATION: 'idle.locationFocus',
        EDIT_CATEGORIES: 'categories',
        EDIT_DATES: 'dates',
        EDIT_GUESTS: 'guests',
        CANCEL_EDIT: 'idle',
        SET_FILTERS: {
          actions: [
            assign({
              filters: (_, { filters }) => {
                if (isDev) console.log('SET_FILTERS', filters)
                const area = filters.area
                // area has a within but we are unpicking it, so pack it back in
                if (area && filters.within) area.rangeMiles = filters.within
                return filters
              },
              searchInputValue: (_, { filters }) => {
                if (filters.bounds) {
                  return { type: 'BOUNDS' }
                }
                if (filters.placename) {
                  return { type: 'CUSTOM', text: filters.placename }
                }
                if (filters.area) {
                  return { type: 'LOCATION' }
                }
                return undefined
              },
              selectedLocation: () => {
                return undefined
              },
            }),
          ],
        },
        CLEAR_SEARCH_PARAMS: {
          target: 'idle',
          actions: [
            'clearLocation',
            assign({
              searchInputValue: (_) => undefined,
            }),
            assign({
              filters: (_) => ({ ...getDefaultSearchFilters(), cleared: true }),
            }),
          ],
        },
        CLEAR_LOCATION: {
          target: 'idle',
          actions: 'clearLocation',
        },
        CLEAR_INPUT: {
          target: 'idle',
          actions: [
            // 'clearLocation',
            assign({
              // filters: ({ filters }) => ({
              //   ...filters,
              //   bounds: undefined,
              //   area: undefined,
              //   hierarchyPath: undefined,
              // }),
              searchInputValue: (_) => undefined,
            }),
          ],
        },
        SET_LOCATION_HIERARCHY: {
          target: 'idle',
          actions: [
            assign({
              filters: ({ filters }, { data }) => {
                return {
                  ...locationDataFilters({ data, filters }),
                  bounds: undefined,
                  cleared: false,
                  placename: undefined,
                }
              },
              selectedLocation: (_, { data }) => data,
              searchInputValue: (_, { data }) => ({
                type: 'LOCATION',
                text: data.name,
              }),
              searchTerm: (_) => undefined,
            }),
          ],
        },
        SET_LOCATION: {
          target: 'idle',
          actions: [
            assign({
              filters: ({ filters }, { data }) => {
                return {
                  ...locationDataFilters({ data, filters }),
                  bounds: undefined,
                  cleared: false,
                  placename: data.dataType !== 'custom' ? undefined : data.name,
                }
              },
              selectedLocation: (_, { data }) => data,
              searchInputValue: (_, { data, inputValue }) => ({
                type: 'CUSTOM',
                text: inputValue
                  ? inputValue
                  : data.dataType === 'campsite'
                    ? data.inputValue
                    : data.name,
              }),
              searchTerm: (_, { inputValue }) => inputValue,
            }),
          ],
        },
        SET_WITHIN: {
          actions: [
            assign({
              filters: ({ filters }, { within }) => {
                const area = {
                  ...filters.area,
                  rangeMiles: within,
                }
                return {
                  ...filters,
                  within,
                  area,
                }
              },
            }),
          ],
        },
        SET_INPUT_VALUE: {
          target: 'idle.history',
          actions: assign({
            searchInputValue: (_, { value }) => ({
              type: 'CUSTOM',
              text: value,
            }),
            filters: ({ filters }, { value }) => ({
              ...filters,
              hierarchyPath: undefined,
              placename: undefined,
              cleared: false,
            }),
          }),
        },
        SET_MY_LOCATION: {
          target: 'idle.history',
          actions: assign({
            filters: ({ filters }, { area }) => ({
              ...filters,
              placename: undefined,
              area,
              bounds: undefined,
              hierarchyPath: undefined,
            }),
            selectedLocation: (_) => undefined,
            searchInputValue: (_) => undefined,
          }),
        },
      },
      states: {
        idle: {
          initial: 'noFocus',
          states: {
            noFocus: {},
            locationFocus: {},
            history: {
              type: 'history',
              history: 'shallow',
            },
          },
        },
        categories: {
          on: {
            SET_CATEGORIES: {
              actions: assign({
                filters: (context, { categoryIds }) => ({
                  ...context.filters,
                  categoryIds,
                  cleared: false,
                }),
              }),
            },
          },
        },
        dates: {
          on: {
            SET_DATES: {
              target: 'idle',
              actions: assign({
                filters: (context, { dateRange }) => ({
                  ...context.filters,
                  dates: dateRange
                    ? {
                      arrive: dateRange.arrive,
                      depart: dateRange.depart
                        ? dateRange.depart
                        : addDays(1, dateRange.arrive),
                    }
                    : undefined,
                  cleared: false,
                }),
              }),
            },
            CLEAR_DATES: {
              target: 'dates',
              actions: assign({
                filters: (context) => ({
                  ...context.filters,
                  dates: undefined,
                  cleared: false,
                  priceRange: undefined,
                }),
              }),
            },
          },
        },
        guests: {
          on: {
            SET_GUESTS: {
              target: 'idle',
              actions: assign({
                filters: (context, { guests }) => ({
                  ...context.filters,
                  party: guests,
                  cleared: false,
                }),
              }),
            },
          },
        },
      },
    },
    {
      actions: {
        clearLocation: assign({
          selectedLocation: (_) => {
            return undefined
          },
        }),
      },
    },
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CampsiteWizardMachineInterpret = Interpreter<Context, any, Events>
