
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { LocationWizardSuggestion } from './types'

@Component({
  components: {
    'location-wizard-hierarchy-suggestion': () =>
      import('./non-autoload-components/LocationWizardHierarchySuggestion.vue'),
    'location-wizard-campsite-suggestion': () =>
      import('./non-autoload-components/LocationWizardCampsiteSuggestion.vue'),
    'location-wizard-custom-suggestion': () =>
      import('./non-autoload-components/LocationWizardCustomSuggestion.vue'),
  },
})
export default class LocationSectionSuggestableInput extends Vue {
  @Prop()
    query: string

  @Prop()
    suggestion: LocationWizardSuggestion
}
