import { render, staticRenderFns } from "./CampsitesSearchWizardMain.vue?vue&type=template&id=53f64284&scoped=true"
import script from "./CampsitesSearchWizardMain.vue?vue&type=script&lang=ts"
export * from "./CampsitesSearchWizardMain.vue?vue&type=script&lang=ts"
import style0 from "./CampsitesSearchWizardMain.vue?vue&type=style&index=0&id=53f64284&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f64284",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,LocationSection: require('/app/apps/search-wizard/campsites-search/location/components/LocationSection.vue').default,AccommodationWizard: require('/app/apps/search-wizard/wizards/accommodation/AccommodationWizard.vue').default,DatesWizard: require('/app/apps/search-wizard/wizards/dates/DatesWizard.vue').default,GuestsWizard: require('/app/apps/search-wizard/wizards/guests/GuestsWizard.vue').default,BaseSubmitButton: require('/app/components/base/BaseSubmitButton.vue').default,WizardFooter: require('/app/apps/search-wizard/components/WizardFooter.vue').default,WizardContainer: require('/app/apps/search-wizard/components/WizardContainer.vue').default})
