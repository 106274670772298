import { getTransformObject } from '~/shared/photos/transforms'
import { langFallback$Fetch } from '../../lang/utils/langfetch'
import { RecentCampsiteData } from './types'

export class RecentCampsiteDataRepositoryFetch {
  async getById(id: string, langCode: string): Promise<RecentCampsiteData> {
    const data: any = await langFallback$Fetch(
      langCode,
      `/_/recent-campsite-details/${id}/`,
    )
    return {
      ...data,
      id,
      primaryPhoto: {
        caption: data.primaryPhoto?.caption || '',
        url: getTransformObject<{ photo: string }>(
          'recentCampsite',
          data.primaryPhoto?.url?.masterImage,
        )?.photo,
        masterImage: data.primaryPhoto?.url?.masterImage,
      },
    }
  }
}
